import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';

import {
  CallSvg,
  ChatSvg,
  If,
  VerifiedSvg,
  useCall,
  useLayoutDisplay,
} from '../../common';
import { BackSvg } from '../layout-header-svg.component';
import { useChatStore } from '../../stores';
import { cssObjectToString, RouteData } from '../../utils';

const { Header } = Layout;

const CharacterHeader: FC<{
  routeData: RouteData;
  backPath: string;
  width?: string;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({
  routeData,
  backPath,
  width,
  maxWidth,
  setVisible,
  style,
  ...props
}) => {
  const { character } = useChatStore();
  const { isMobile } = useLayoutDisplay();

  const { start: startCall } = useCall(character);

  useEffect(() => {
    setVisible(true);
  }, [routeData]);

  const hasBack = routeData.allowBackToMain || backPath !== '/';

  const isNatalie = character?.id === '8144ea24-3435-4095-ac62-ef5c6d9f5c0e';

  return (
    <Header
      style={{
        width,
        maxWidth,
        borderRadius: routeData.flatHeader ? 0 : undefined,
        ...style,
      }}
      {...props}
    >
      <Helmet>
        <title>{character ? character?.name + ' - Swoons' : 'Swoons'} </title>
      </Helmet>

      <div
        className="header-container"
        style={hasBack ? undefined : { flexDirection: 'row-reverse' }}
      >
        <If condition={hasBack}>
          <Link to={backPath}>
            <div className="button-circle">
              <Icon component={BackSvg} />
            </div>
          </Link>
        </If>

        <div className={cssObjectToString({ title: true, fit: isMobile })}>
          <div style={{ marginRight: '4px' }}>{character?.name}</div>{' '}
          {character?.name && <Icon component={VerifiedSvg} />}
        </div>

        <div>
          <Link
            to={`/${character?.handle}/chat`}
            style={{ marginRight: '10px' }}
          >
            <button className="header-button-circle accent">
              <Icon component={ChatSvg} style={{ width: '20px' }} />
            </button>
          </Link>

          <If condition={character && !isNatalie}>
            <button
              className="header-button-circle accent"
              onClick={startCall}
              style={{ marginRight: '10px' }}
            >
              <Icon component={CallSvg} style={{ width: '20px' }} />
            </button>
          </If>
        </div>
      </div>
    </Header>
  );
};

export default CharacterHeader;
