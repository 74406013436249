import { useParams } from 'react-router-dom';

import MediaViewer from './media-viewer/media-viewer.component';
import MessageList from './message-list/message-list.component';
import { ParsedMessage } from './message-list/parsed-message.interface';
import { CallModal, If, useChat } from '../../../../common';
import MediaGallery from './media-gallery/media-gallery.component';
import { ChatDrawer } from './chat-drawer/chat-drawer.component';
import { PricingDrawer } from './pricing-drawer/pricing-drawer.component';
import InitialChatSettingsModal from './initial-chat-settings-modal/initial-chat-settings-modal.component';
import AgeWarningModal from './age-warning-modal/age-warning-modal.component';

import './chat.component.css';

const Chat: React.FC = () => {
  const params = useParams();

  const {
    id: chatId,
    messages,
    loadMoreMessages,
    isLoadingMessages,
    viewedMedia,
    setViewedMedia,
    unlockMessageMedia,
    tab,
    character,
  } = useChat(params.handle);

  const isNatalie = character?.id === '8144ea24-3435-4095-ac62-ef5c6d9f5c0e';

  return (
    <>
      <If condition={tab === 'chat'}>
        <div className="chat-centered-container">
          <MessageList
            messages={messages}
            loadMoreMessages={loadMoreMessages}
            isLoadingMessages={isLoadingMessages}
            unlockMessageMedia={(data: ParsedMessage) => {
              unlockMessageMedia(chatId, data?.id);
            }}
            viewMedia={(data: ParsedMessage) => setViewedMedia(data.media)}
            alwaysShowText={character?.alwaysShowText}
            markdownEnabled={isNatalie}
          />
        </div>
      </If>

      <If condition={tab === 'media'}>
        <MediaGallery chatId={chatId} setViewedMedia={setViewedMedia} />
      </If>

      <MediaViewer
        media={viewedMedia}
        close={() => setViewedMedia(undefined)}
      />

      <InitialChatSettingsModal />
      <AgeWarningModal />
      <CallModal />
      <ChatDrawer />
      <PricingDrawer />
    </>
  );
};

export default Chat;
