import { FC } from 'react';
import Markdown from 'marked-react';

import { CodeBlock } from './code-block';

const renderer = {
  code(snippet: string, language: string) {
    return (
      <CodeBlock
        key={snippet.substring(0, 20)}
        code={snippet}
        language={language}
        className="code-block"
        style={{ padding: '16px' }}
      />
    );
  },
};

const MessageTextParser: FC<{
  value?: string;
  markdownEnabled?: boolean;
  style?: React.CSSProperties;
}> = ({ value, markdownEnabled, style }) => {
  if (!markdownEnabled) {
    return (
      <div
        style={{
          ...style,
          whiteSpace: 'pre-wrap',
          wordWrap: 'normal',
        }}
      >
        <span>{value}</span>
      </div>
    );
  }

  return (
    <div
      style={{
        ...style,
        whiteSpace: 'pre-wrap',
        wordWrap: 'normal',
      }}
    >
      <Markdown value={value} renderer={renderer} />
    </div>
  );
};

export default MessageTextParser;
