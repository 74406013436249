import { useCallback, useEffect, useRef } from 'react';

import MessageBox from './message-box.component';
import { Message } from '../../../../../api';
import { ParsedMessage } from './parsed-message.interface';
import { useParsedMessages } from './use-parsed-messages.hook';

import './message-list.component.css';

const MessageList: React.FC<{
  messages?: Message[];
  loadMoreMessages: () => string | undefined;
  isLoadingMessages: boolean;
  unlockMessageMedia?: (data: ParsedMessage) => any;
  viewMedia?: (data: ParsedMessage) => any;
  alwaysShowText?: boolean;
  markdownEnabled?: boolean;
  style?: React.CSSProperties;
}> = ({
  messages,
  loadMoreMessages,
  isLoadingMessages,
  unlockMessageMedia,
  viewMedia,
  alwaysShowText,
  markdownEnabled,
  style,
}) => {
  const messageListContainerRef = useRef<any>();
  const topMessageId = useRef<string>();
  const topMessageElement = useRef<any>();
  const isLoadingMessagesRef = useRef<boolean>(isLoadingMessages);

  useEffect(() => {
    isLoadingMessagesRef.current = isLoadingMessages;
  }, [isLoadingMessages]);

  const scrollToBottom = useCallback(() => {
    messageListContainerRef.current?.scroll({
      top: messageListContainerRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  }, [messageListContainerRef]);

  const scrollTopMessageIntoView = useCallback(() => {
    topMessageElement.current?.scrollIntoView({ block: 'start' });
  }, [topMessageElement]);

  const handleScroll = useCallback(() => {
    if (!messageListContainerRef.current) return;

    if (messageListContainerRef.current.scrollTop === 0) {
      topMessageId.current = loadMoreMessages();
    }
  }, [messageListContainerRef, loadMoreMessages]);

  const parsedMessages = useParsedMessages({
    alwaysShowText,
    messages,
    onChange: () => {
      if (topMessageElement.current && isLoadingMessagesRef.current) {
        // scrolled up and loaded more paginated results
        scrollTopMessageIntoView();

        setTimeout(() => {
          scrollTopMessageIntoView();

          topMessageId.current = undefined;
          topMessageElement.current = undefined;
        }, 10);
      } else {
        // added a new message
        scrollToBottom();

        setTimeout(() => scrollToBottom(), 10);
      }
    },
  });

  return (
    <div
      className="message-list"
      style={style}
      ref={messageListContainerRef}
      onScroll={handleScroll}
    >
      {parsedMessages?.map((item) => {
        if (item.message) {
          const isTopMessage =
            item.message.id === topMessageId.current &&
            item.message.messageItemIndex === 0;

          return (
            <MessageBox
              data={item.message}
              unlockMessageMedia={unlockMessageMedia}
              viewMedia={viewMedia}
              markdownEnabled={markdownEnabled}
              key={item.message.key}
              ref={isTopMessage ? topMessageElement : undefined}
            />
          );
        } else {
          return (
            <div key={item.date} className="date">
              {item.date}
            </div>
          );
        }
      })}
    </div>
  );
};

export default MessageList;
